// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(//cdn.materialdesignicons.com/5.0.45/css/materialdesignicons.min.css);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Kode+Mono:wght@400..700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.react-datepicker-wrapper {
  display: flex !important;
  flex-grow: 0;
  flex-shrink: 1;
  min-width: unset !important;
  width: auto;
  height: 30px;
}

.react-datepicker__navigation-icon--previous::before {
  width: 14px !important;
  aspect-ratio: 1/1;
  border-color: black !important;
  top: 14px !important;
  height: 7px !important;
}

.react-datepicker__navigation-icon--next::before {
  width: 14px !important;
  aspect-ratio: 1/1;
  border-color: black !important;
  left: -8px !important;
  top: 8px !important;
}

.show-panel-datepicker {
  contain: unset !important;
}

.production-day-selector {
  height: 30px;
}

.react-datepicker {
  font-family: 'Kode Mono', monospace !important;
}

.react-datepicker-popper {
  z-index: 999 !important;
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before {
  top: 0px !important;
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
  top: 1px !important;
}
`, "",{"version":3,"sources":["webpack://./styles/core.css"],"names":[],"mappings":"AAGA;EACE,wBAAwB;EACxB,YAAY;EACZ,cAAc;EACd,2BAA2B;EAC3B,WAAW;EACX,YAAY;AACd;;AAEA;EACE,sBAAsB;EACtB,iBAAiB;EACjB,8BAA8B;EAC9B,oBAAoB;EACpB,sBAAsB;AACxB;;AAEA;EACE,sBAAsB;EACtB,iBAAiB;EACjB,8BAA8B;EAC9B,qBAAqB;EACrB,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,8CAA8C;AAChD;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":["@import url('//cdn.materialdesignicons.com/5.0.45/css/materialdesignicons.min.css');\n@import url('https://fonts.googleapis.com/css2?family=Kode+Mono:wght@400..700&display=swap');\n\n.react-datepicker-wrapper {\n  display: flex !important;\n  flex-grow: 0;\n  flex-shrink: 1;\n  min-width: unset !important;\n  width: auto;\n  height: 30px;\n}\n\n.react-datepicker__navigation-icon--previous::before {\n  width: 14px !important;\n  aspect-ratio: 1/1;\n  border-color: black !important;\n  top: 14px !important;\n  height: 7px !important;\n}\n\n.react-datepicker__navigation-icon--next::before {\n  width: 14px !important;\n  aspect-ratio: 1/1;\n  border-color: black !important;\n  left: -8px !important;\n  top: 8px !important;\n}\n\n.show-panel-datepicker {\n  contain: unset !important;\n}\n\n.production-day-selector {\n  height: 30px;\n}\n\n.react-datepicker {\n  font-family: 'Kode Mono', monospace !important;\n}\n\n.react-datepicker-popper {\n  z-index: 999 !important;\n}\n\n.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before {\n  top: 0px !important;\n}\n\n.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {\n  top: 1px !important;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
